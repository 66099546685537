import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/named
import Button, { ButtonProps as DefaultButtonProps } from '@mui/material/Button';
// import { twMerge } from 'tailwind-merge';
import { styled } from '@mui/material';
import { twMerge } from 'tailwind-merge';
import {
  BUTTON_SIZE_TYPE,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  TRANSPARENT,
} from 'src/components/WidgetMaker/utils/buttonConstant';
import buttonAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ButtonAnimationStyles.module.scss';

type CustomVariant =
  | 'primary'
  | 'secondary'
  | 'secondary-2'
  | 'secondaryFilled'
  | 'secondaryFilled-2'
  | 'tertiary';

interface CustomButtonProps {
  customVariant: CustomVariant;
  customBgColor?: string;
}

const CustomButton = styled(Button)<CustomButtonProps & DefaultButtonProps>(
  ({ customVariant, customBgColor }: any) => ({
    transition: 'all 250ms ',
    transitionDuration: '250ms',
    transitionTimingFunction: 'ease-in',

    '&:hover': {
      opacity: 0.96,
      backgroundColor:
        customBgColor ||
        defaultColors[Object.keys(defaultColors).find((key) => customVariant === key)]
          ?.backgroundColor,
    },
  })
);

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  image?: any;
  imageDirection?: 'left' | 'right';
  size?: 'small' | 'medium' | 'large';
  variant?: CustomVariant;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  showShadow?: boolean;
  removeHoverBg?: boolean;
  onMouseUp?: any;
  onMouseDown?: any;
  btnType?: string;
  buttonData?: any;
  selectedButtonData?: any;
  textualAlignment?: string
}

const defaultStyleAttributes: React.CSSProperties = {
  textTransform: 'none',
  padding: 0,
};

const defaultColors = {
  primary: {
    backgroundColor: '#2B74D8',
    color: '#ffffff',
  },
  secondary: {
    backgroundColor: '#ffffff',
    color: '#2B74D8',
    border: '1px solid #111C361F',
  },
  'secondary-2': {
    backgroundColor: '#ffffff',
    color: '#111C36',
    border: '1px solid #111C361F',
  },
  secondaryFilled: {
    backgroundColor: '#111C360F',
    color: '#2B74D8',
    border: '1px solid #111C361F',
  },
  'secondaryFilled-2': {
    backgroundColor: '#111C360F',
    color: '#111c36',
    border: '1px solid #111C361F',
  },
  'tertiary:': {
    textColor: '#2B74D8',
  },
};

const defaultGeometry: Record<string, React.CSSProperties> = {
  small: {
    fontSize: 10,
    padding: '4px 18px',
  },
  medium: {
    fontSize: 14,
    padding: '8px 24px',
  },
  large: {
    fontSize: 18,
    padding: '12px 30px',
  },
};

export default function CustomizedButton(props: Props) {
  const {
    imageDirection = 'left',
    size = BUTTON_SIZE_TYPE.MEDIUM,
    variant = 'primary',
    removeHoverBg,
    btnType,
    buttonData,
    selectedButtonData,
    textualAlignment = ''
  } = props;
  const stripButtonDefaults = variant === BUTTON_VARIANT.TERTIARY;
  const [isHover, setIsHover] = useState(false);
  const customBtnRef = useRef();
  const colorFromButtonData = buttonData?.btnStyleConfig?.textColor;
  const bgColorFromButtonData =
    btnType === BUTTON_TYPE.SOLID
      ? buttonData?.btnStyleConfig?.backgroundColor
      : TRANSPARENT;
  const borderColorFromButtonData =
    btnType === BUTTON_TYPE.OUTLINE ? buttonData?.btnStyleConfig?.backgroundColor : '';
  const btnColor = buttonData && colorFromButtonData ? colorFromButtonData : props?.color;
  const btnBgColor =
    buttonData && bgColorFromButtonData ? bgColorFromButtonData : props?.backgroundColor;
  const borderColor =
    buttonData && borderColorFromButtonData
      ? borderColorFromButtonData
      : props?.borderColor;

  function getBackgroundColorForOutline(currentColor) {
    switch (currentColor?.toLowerCase()) {
      case '#ffffff':
        return {
          background: '#ffffff',
          text: '#000000',
        };
      case '#000000':
        return {
          background: '#000000',
          text: '#ffffff',
        };
      default:
        return {
          background: currentColor,
          text: '#ffffff',
        };
    }
  }

  useEffect(() => {
    if (btnType === BUTTON_TYPE.OUTLINE && customBtnRef?.current) {
      const backgroundColor = getBackgroundColorForOutline(btnColor).background;
      (customBtnRef.current as any).style.setProperty('--before-bg', backgroundColor);
    }
    else if(btnType === BUTTON_TYPE.TEXTUAL && customBtnRef?.current) {
      (customBtnRef.current as any).style.setProperty('--textual-bg', btnColor);
    }
  }, [btnType, btnColor]);

  // const defaultSizeClasses = (() => {
  //   const strippedDownDefaults = ' !tw-py-0 !tw-px-0 !tw-w-auto';
  //   switch (size) {
  //     case 'small':
  //       return (
  //         '!tw-text-[10px] ' +
  //         (!stripButtonDefaults ? ' !tw-py-[4px] !tw-px-[18px]' : strippedDownDefaults)
  //       );
  //     case 'medium':
  //       return (
  //         '!tw-text-[14px] ' +
  //         (!stripButtonDefaults ? ' !tw-py-[8px] !tw-px-[24px]' : strippedDownDefaults)
  //       );
  //     case 'large':
  //       return (
  //         '!tw-text-[18px] ' +
  //         (!stripButtonDefaults ? ' !tw-py-[12px] !tw-px-[30px]' : strippedDownDefaults)
  //       );
  //     default:
  //       return (
  //         '!tw-text-[14px] ' +
  //         (!stripButtonDefaults ? ' !tw-py-[8px] !tw-px-[24px]' : strippedDownDefaults)
  //       );
  //   }
  // })();

  // const defaultColorsClasses = (() => {
  //   switch (variant) {
  //     case 'primary':
  //       return '!tw-bg-[#2B74D8] !tw-text-[#ffffff]';
  //     case 'secondary':
  //       return '!tw-text-[#2B74D8] !tw-bg-[#ffffff] !tw-border-[1px] !tw-border-solid !tw-border-[#111C361F]/[0.12] ';
  //     case 'secondary-2':
  //       return '!tw-text-newBlack !tw-bg-[#ffffff] !tw-border-[1px] !tw-border-solid !tw-border-[#111C361F]/[0.12] ';
  //     case 'secondaryFilled':
  //       return '!tw-text-[#2B74D8] !tw-bg-[#111C360F]/[0.06]  !tw-border-[1px] !tw-border-solid !tw-border-[#111C361F]/[0.02] ';
  //     case 'secondaryFilled-2':
  //       return '!tw-text-newBlack !tw-bg-[#111C360F]/[0.06]  !tw-border-[1px] !tw-border-solid !tw-border-[#111C361F]/[0.02] ';
  //     case 'tertiary':
  //       return '!tw-text-[#2B74D8] ';
  //     default:
  //       return '!tw-bg-[#2B74D8] !tw-text-[#ffffff]';
  //   }
  // })();

  const dropShadaow = (() => {
    if (!props.showShadow) {
      return '';
    }

    switch (variant) {
      case BUTTON_VARIANT.SECONDARY:
      case BUTTON_VARIANT.SECONDARY2:
        return ' !tw-shadow-[0px_2px_6px_0px_#0000001F] ';
      case BUTTON_VARIANT.SECONDARYFILLED:
      case BUTTON_VARIANT.SECONDARYFILLED2:
        return ' !tw-shadow-[0px_2px_6px_0px_#0000001F] ';
    }
  })();

  const getCustomBgColor = () => {
    if (removeHoverBg) {
      return TRANSPARENT;
    }
    return (
      props.className?.split?.(' tw-bg-[')?.[1]?.split?.(']')?.[0] ||
      props.className?.split?.(' !tw-bg-[')?.[1]?.split?.(']')?.[0] ||
      btnBgColor
    );
  };

  return (
    <CustomButton
      customVariant={variant}
      customBgColor={getCustomBgColor()}
      onClick={(event) => {
        event.stopPropagation();
        props?.onClick && props?.onClick(event);
      }}
      ref={customBtnRef}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      disabled={props.disabled}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        ...defaultStyleAttributes,
        ...defaultColors[variant],
        ...(!stripButtonDefaults ? defaultGeometry[size] : {}),
      }}
      className={twMerge(
        'tw-relative tw-flex tw-items-center tw-justify-center tw-gap-[10px] tw-font-inter tw-font-semibold tw-leading-[1] tw-tracking-normal',
        imageDirection === 'left' ? '!tw-flex-row ' : '!tw-flex-row-reverse ',
        dropShadaow,
        props.className,
        selectedButtonData?.animationColor ? selectedButtonData?.animationColor : '',
        selectedButtonData?.customClasses ? selectedButtonData.customClasses : '',
        buttonData?.buttonAnimationConfig?.classKey
          ? buttonAnimationStyle[buttonData?.buttonAnimationConfig?.classKey]
          : ''
      )}
      style={{
        borderColor: borderColor,
        backgroundColor: variant === BUTTON_VARIANT.TERTIARY ? '' : btnBgColor,
        ...((variant === BUTTON_VARIANT.TERTIARY && textualAlignment) ? {justifyContent : textualAlignment} : {})
      }}
    >
      {props.image ? (
        typeof props.image === 'object' ? (
          props.image
        ) : (
          <img src={props.image} />
        )
      ) : null}
      <p
        style={{
          color:
            isHover && btnType === BUTTON_TYPE.OUTLINE
              ? getBackgroundColorForOutline(btnColor).text
              : btnColor,
        }}
        className="!tw-z-[2] !tw-m-0"
      >
        {props.children}
      </p>
    </CustomButton>
  );
}
